<template>

  <div style="float: left;width: 280px;">
    <div class="new-patent">
      <div class="np-title">
        <img :src="require('../../assets/image/index/new_patent.png')" class="np-title-img" />
        <div>最新专利</div>
      </div>
      <router-link v-for="(item) in newestPatentList" :key="item.id"
                   :to="{path: 'good',query:{'goodId':item.id}}" target="_blank" class="np-main">
        <img :src="item.mainImageUrl" class="np-main-img" />
        <div class="np-main-right">
          <div class="npmr-text ellipse-2">{{item.name}}</div>
          <div class="npmr-cn">{{item.an}}</div>
          <div class="npmr-time">{{item.createdTime}}</div>
        </div>
      </router-link>
    </div>


    <div class="new-patent" style="margin-top: 16px">
      <div class="np-title">
        <img :src="require('../../assets/image/index/new_result.png')" class="np-title-img" />
        <div>最新成果</div>
      </div>
      <router-link v-for="(item) in newestAchievementList" :key="item.id"
                   :to="{path:'/home/PatentSearchDetail',query:{id:item.id}}" target="_blank" class="np-main">
        <div class="np-main-right">
          <div class="npmr-text ellipse-1">{{item.title}}</div>
          <div class="npmr-des ellipse-3">{{item.abstractDesc}}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getNewestAchievementsList, getNewestPatentList } from "../../plugins/api/encyclopediaKnowledgeApi";

export default {
  name: "",
  data() {
    return {
      newestAchievementList: [],//最新成果列表
      newestPatentList: []//最新专利列表
    };
  },
  mounted() {
    this.getNewestPatentList();
    this.getNewestAchievementList();
  },
  methods: {
    //获取最新专利列表
    async getNewestPatentList() {
      const params = {
        pageNum: 1,
        pageSize: 3
      };
      const json = await getNewestPatentList(params);
      if (json && json.code === 0) {
        if (json.result && json.result.list && json.result.list.length > 0) {
          this.newestPatentList = json.result.list;


        }
      }
    },
    //获取最新成果列表
    async getNewestAchievementList() {
      const params = {
        pageNum: 1,
        pageSize: 3
      };
      const json = await getNewestAchievementsList(params);
      if (json && json.code === 0) {
        if (json.result && json.result.list && json.result.list.length > 0) {
          this.newestAchievementList = json.result.list;
          console.log(json.result);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .ivu-page-item-active {
    background-color: #2d8cf0;
  /*background-color: #fc7c40;*/
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}

.per_content div {
  margin-bottom: 5px;
}

.new-patent {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.np-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  font-weight: 500;
  color: #333333;
  padding-left: 10px;
  height: 39px;
  border-bottom: 1px dashed rgba(229, 232, 237, 1);
}

.np-title-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.np-main {
  display: flex;
  flex-direction: row;
  height: 100px;
  padding: 10px;
}

.np-main-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.np-main-right{
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
}

.npmr-text{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC,serif;
  font-weight: 500;
  color: #333333;
}

.npmr-cn{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC,serif;
  font-weight: 400;
  color: #666666;
}

.npmr-time{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC,serif;
  font-weight: 400;
  color: #999999;
}

.npmr-des{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC,serif;
  font-weight: 400;
  color: #999999;
}
</style>
